import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import styled from "styled-components";
import PropTypes from "prop-types";
import Box from "reusecore/src/elements/Box";
import Text from "reusecore/src/elements/Text";
import Heading from "reusecore/src/elements/Heading";
import FeatureBlock from "../../components//FeatureBlock";
import Container from "../../components//Container";
import SuccessSectionWrapper from "./successSection.style";
import Input from "../../components/Input";
import Select from "../../components/Select";
import Button from "reusecore/src/elements/Button";
import party from "./PartyIllustration";

const SuccessSection = ({
  row,
  col,
  sectionHeader,
  sectionTitle,
  sectionSubTitle,
  featureTitle,
  featureDescription,
  iconStyle,
  contentStyle,
  blockWrapperStyle,
  btnStyle,
  firstname,
  title,
  message,
}) => {
  return (
    <SuccessSectionWrapper id="service_section">
      <Container>
        <Box {...sectionHeader}>
          <Heading content={title} {...sectionTitle} />
          <Text content={message} {...sectionSubTitle} />
        </Box>
        <Box className="row" {...row} justifyContent="center">
          <Box className="col" {...col} flexDirection="column" flexBox={true}>
            <Button
              type="submit"
              title="Tell us at least why you are leaving us 😅"
              {...btnStyle}
              style={{ marginBottom: "1rem" }}
              onClick={() => {
                window.location = "https://konsalex.typeform.com/to/Bk9YmB";
              }}
            />
            <video controls width="100%" autoplay={true}>
              <source
                src="https://media.giphy.com/media/l1KVaj5UcbHwrBMqI/giphy.mp4"
                type="video/mp4"
              />
              Sorry, your browser doesn't support embedded videos.
            </video>
            <Link to="/">
              <Button
                type="submit"
                title="GO HOME"
                {...btnStyle}
                style={{ marginTop: "1rem" }}
              />
            </Link>
          </Box>
        </Box>
      </Container>
    </SuccessSectionWrapper>
  );
};

// SuccessSection style props
SuccessSection.propTypes = {
  sectionHeader: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  featureTitle: PropTypes.object,
  featureDescription: PropTypes.object,
};

// SuccessSection default style
SuccessSection.defaultProps = {
  // section header default style
  sectionHeader: {
    mb: ["40px", "40px", "40px", "80px"],
  },
  // sub section default style
  sectionSubTitle: {
    as: "span",
    display: "block",
    textAlign: "center",
    fontSize: "16px",
    letterSpacing: "0.15em",
    fontWeight: "700",
    color: "#0f2137",
    letterSpacing: "-0.025em",
    mb: "10px",
  },
  // section title default style
  sectionTitle: {
    textAlign: "center",
    fontSize: ["20px", "24px"],
    fontWeight: "500",
    color: "#3E21DE",
    mb: "24px",
    mt: "40px",
  },
  // feature row default style
  row: {
    flexBox: true,
    flexWrap: "wrap",
  },
  // feature col default style
  col: {
    width: [1, 1 / 2, 1 / 2, 1 / 3],
  },
  // feature block wrapper default style
  blockWrapperStyle: {
    p: ["30px", "20px", "20px", "20px"],
  },
  // feature icon default style
  iconStyle: {
    width: ["70px", "75px", "75px", "84px"],
    height: ["70px", "75px", "75px", "84px"],
    borderRadius: "16px",
    bg: "#93d26e",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: ["32px", "36px"],
    color: "#3E21DE",
    overflow: "hidden",
    mb: ["20px", "20px", "20px", "30px"],
    // borderBottomLeftRadius: "50%"
  },
  // feature content default style
  contentStyle: {
    textAlign: "left",
  },
  // feature title default style
  featureTitle: {
    fontSize: ["18px", "20px"],
    fontWeight: "400",
    color: "#0f2137",
    lineHeight: "1.5",
    mb: ["10px", "10px", "10px", "20px"],
    letterSpacing: "-0.020em",
    textAlign: "center",
  },
  // feature description default style
  featureDescription: {
    fontSize: "16px",
    lineHeight: "1.75",
    color: "#343d4ccc",
  },
  btnStyle: {
    fontSize: ["13px", "16px"],
    fontWeight: "500",
    colors: "primaryWithBg",
    width: "100%",
  },
};

export default SuccessSection;

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const AsteriskSvg = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path d="M18.562,14.63379,14.00031,12,18.562,9.36621a1.00016,1.00016,0,0,0-1-1.73242L13,10.26776V5a1,1,0,0,0-2,0v5.26776l-4.562-2.634a1.00016,1.00016,0,0,0-1,1.73242L9.99969,12,5.438,14.63379a1.00016,1.00016,0,0,0,1,1.73242L11,13.73224V19a1,1,0,0,0,2,0V13.73224l4.562,2.634a1.00016,1.00016,0,0,0,1-1.73242Z" />
  </svg>
);

const Label = styled.label`
  font-family: "Yantramanav", sans-serif !important;
  font-size: 16px;
  color: #616161;
  margin-left: 1rem;
  text-transform: capitalize;
`;
const Wrapper = styled.div``;

const StyledInput = styled.input`
  font-family: "Yantramanav", sans-serif !important;
  font-size: 18px;

  background-color: #f5f5f5;
  border: 1px solid #f5f5f5;
  border-radius: 4px;
  padding: 12px 16px;
  margin: 4px 0 16px 0;
  width: 100%;
  outline: none;
  font-size: 1rem;

  &:focus {
    border: 1px solid #3e21de;
    transition: border 0.7s ease-in-out;
  }
`;

class Input extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    defaultValue: PropTypes.string,
    type: PropTypes.oneOf(["text", "email", "password", "number"]),
    required: PropTypes.bool,
    pattern: PropTypes.string,
    placeholder: PropTypes.string,
    style: PropTypes.object,
  };

  static defaultProps = {
    type: "text",
    required: false,
    defaultValue: "",
  };

  render() {
    return (
      <Wrapper>
        {!this.props.isHidden && (
          <Label>
            {this.props.label}
            {this.props.isOptional && (
              <span style={{ fontStyle: "italic", color: "#aaa" }}>
                {" "}
                - Optional
              </span>
            )}
          </Label>
        )}
        <StyledInput
          id={this.props.id}
          type={this.props.type}
          name={this.props.name}
          defaultValue={this.props.defaultValue}
          onChange={(event) => this.props.onChange(event.target.value)}
          required={this.props.required}
          pattern={this.props.pattern}
          placeholder={this.props.placeholder}
          value={this.props.value}
          style={this.props.style}
        />
      </Wrapper>
    );
  }
}

export default Input;

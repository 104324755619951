import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";
import UnsubscribeSection from "../containers/UnsubscribeSection";
import MainLayout from "../components/MainLayout";

export default ({ location }) => {
  const [isUnsubscribed, setIsUnsubscribed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function unsubscribeUser() {
    if (typeof window !== "undefined") {
      const urlParams = new URLSearchParams(window.location.search);
      const username = urlParams.get("id");

      const formData = new FormData();
      formData.append("uid", username);

      setIsLoading(true);

      fetch("https://api.visualeyes.design/unsubscribe", {
        headers: { Accept: "application/json" },
        method: "POST",
        body: formData,
      })
        .then((response) => {
          const { status } = response;
          try {
            if (status === 200) {
              setIsUnsubscribed(true);
              setIsLoading(false);
              return;
            } else if (status === 400) {
              throw "Missing data field";
            } else {
              throw "Server exception traceback";
            }
          } finally {
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          alert(
            "An error has been occured during the form submission 😞 Please try again."
          );
          navigate("/");

          return error;
        });
    }
  }

  useEffect(() => {
    unsubscribeUser();
  }, []);

  return (
    <MainLayout title="Unsubscribe" pathname="/unsubscribe">
      {isLoading
        ? "Loading..."
        : isUnsubscribed && (
            <UnsubscribeSection
              title={"It's not the same without you 😭"}
              message={
                "You're successfully unsubscribed from all of our upcoming email about new features and design news in general!"
              }
            />
          )}
    </MainLayout>
  );
};
